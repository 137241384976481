import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/navbar';
import Rectangle from '../images/Rectangle4.png';
import '../fonts.css';
import Swal from 'sweetalert2'; 
import {Helmet} from "react-helmet";
import banner from '../images/imagenbannergmcp2.svg';
import elipse6 from '../images/Ellipse6.png';
import cuadro from '../images/Rectangle18.png';
import mision from '../images/Mision.png';
import vision from '../images/Vision.png';
import objetivos from '../images/Objetivos.png';
import imgcontacto from '../images/imagencontacto.svg';
import iconoob from '../images/Iconodeobjetivos.png';
import ellipse5 from '../images/Ellipse5.png';
import img1 from '../images/Group 290501.png';
import img8 from '../images/22.png';

const Index = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

   useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  

  const slideStyle = {
    position: 'relative',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${Rectangle})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

 const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false); // Estado para manejar el loading

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Activar el loading

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    };

    try {
      const response = await fetch('http://101.44.24.18:8484/api/contact/send-message', requestOptions);
      if (!response.ok) {
        throw new Error('Error al enviar el mensaje');
      }
      const data = await response.json();
      console.log('Mensaje enviado:', data);

      // Mostrar alerta de éxito
      Swal.fire({
        title: '¡Éxito!',
        text: 'Tu mensaje ha sido enviado correctamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });

      // Limpiar el formulario
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      // Mostrar alerta de error
      Swal.fire({
        title: 'Error',
        text: 'No se pudo enviar el mensaje. Por favor, inténtalo de nuevo más tarde.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    } finally {
      setLoading(false); // Desactivar el loading después de la solicitud
    }
  };


  return (

    
    
    <div id="root" className="flex flex-col h-full">

       <Helmet>
        <title>Inicio</title>
        <meta name="description" content="Bienvenido a GMCP Recursos Humanos, expertos en gestión de personal y contratación." />
        <meta name="keywords" content="GMCP, Recursos Humanos, Contratación, Personal, Misión, Visión, Objetivos" />
      </Helmet>

 <div className="content">
 <Navbar setIsMenuOpen={setIsMenuOpen} />

<div className="content">
  <div className="slide-section relative flex-shrink-0" style={slideStyle}>
    {/* Only hide this section when isMenuOpen is true */}
 <div
  className={`absolute top-16 left-4 sm:top-24 sm:left-8 text-left`}
  style={{ color: '#E3DA00', fontFamily: 'Verlag Black' }}
>
  <p className="text-xl sm:text-2xl font-bold mb-2">Habilidades esenciales para la vida</p>

  <p
    className="text-sm sm:text-base text-white font-helvetica-light mb-4"
    style={{
      maxWidth: '478px',
      opacity: '0.9',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '1.5',
      marginTop: '0px',
    }}
  >
   Te orientamos en el desarrollo de habilidades fundamentales para tu vida personal, académica y profesional. Nos enfocamos especialmente en las habilidades blandas y en algunas especialidades técnicas, básicas para el trabajo de oficina.
  </p>

  <motion.button
    className="d-block d-sm:inline-block mt-6 sm:mt-6 lg:mt-4 px-6 py-3 bg-green-700 text-white rounded-md hover:bg-green-900 transition-all move-button-mobile"
    style={{
      width: '200px',
      fontSize: '14px',
      opacity: '1',
      transition: 'background-color 0.3s, transform 0.3s, bottom 0.3s',
      position: 'relative',
      bottom: '10px',
    }}
    onClick={() => {
      const target = document.getElementById('informacion');
      target.scrollIntoView({ behavior: 'smooth' });
    }}
    onMouseOver={(e) => {
      e.target.style.backgroundColor = '#145700';
      e.target.style.transform = 'scale(1.05)';
      e.target.style.bottom = '5px';
    }}
    onMouseOut={(e) => {
      e.target.style.backgroundColor = '#1F7B00';
      e.target.style.transform = 'scale(1)';
      e.target.style.bottom = '10px';
    }}
    variants={fadeIn}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    Saber más
  </motion.button>
</div>

    {/* The image will remain visible */}
    <motion.img
      src={banner}
      alt="Slide 1"
      className="w-full h-full object-cover"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
        style={{ userSelect: 'none', pointerEvents: 'none' }}

    />
  </div>
</div>


<div className="min-h-screen bg-[#001d4a] flex flex-col lg:flex-row justify-center items-center p-4 overflow-hidden relative"
id="informacion"
 style={{ marginBottom: 0 }}
>

  <div className="max-w-lg lg:max-w-2xl mt-12 flex-grow text-center lg:text-left">
    <p className="text-gray-300 font-verlag-black text-2xl lg:text-4xl mb-0 lg:mt-12">
      <span>Cada paso cuenta</span>
    </p>
 <p
              className="mt-2 mb-0 text-lg text-gray-300 font-verlag-xlight lg:text-xl lg:mt-10"
              style={{ maxHeight: "170px", overflow: "hidden" }}
            >
              Cada curso, cada taller, cada experiencia laboral <br />
              por pequeña que sea, suma a tu perfil profesional. <br />
              No subestimes el valor de tus esfuerzos diarios.
    </p>
  </div>

<div className="flex flex-col items-center justify-center mt-5 lg:flex-row lg:mt-0">
            {[
              {
                title: "Misión",
                img: mision,
                description:
                  "Fomentamos el desarrollo de habilidades blandas y técnicas mediante cursos prácticos y breves, que involucren completamente a la persona en situaciones reales, fortaleciendo sus relaciones personales y profesionales para enfrentar los desafíos con mayor confianza y éxito.",
              },
              {
                title: "Visión",
                img: vision,
                description:
                  "GMCPeople se dedica a apoyar a personas de todas las edades, desde jóvenes que buscan integrarse al mundo laboral hasta adultos que necesitan mejorar sus habilidades blandas y técnicas para tener éxito personal y profesional en el entorno actual.",
              },
              {
                title: "La capacitación es clave",
                img: objetivos,
                description:
                  "Invertir tiempo en aprender nuevas habilidades y mejorar las que ya tienes te abrirá puertas. El conocimiento es una herramienta poderosa que te diferencia en el mercado laboral.",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                className="relative max-w-sm mx-4 my-4 text-center lg:max-w-md"
              >
              <img
  src={cuadro}
  style={{ userSelect: 'none', pointerEvents: 'none' }}

  alt={`Cuadro Image ${index + 1}`}
  className="w-full h-auto mb-4"
/>

                <p
                  className="absolute text-white font-verlag-book"
                  style={{
                    top: "1rem",
                    left: "0",
                    right: "0",
                    fontSize: "1.655rem",
                  }}
                >
                  {item.title}
                </p>

                <p
                  className="absolute text-white font-verlag-condensed-xlight"
                  style={{
                    top: "5rem",
                    left: "0",
                    right: "0",
                    fontSize: "1.250rem",
                    justifyContent: "center",
                    textAlign: "justify",
                  }}
                >
                  {item.description}
                </p>
                <img
                  src={item.img}
                  className="absolute"
                  style={{
                    top: "18rem",
                    serSelect: 'none',
                  pointerEvents: 'none',
                    left: "80%",
                    transform: "translateX(-50%)",
                    maxWidth: "100%",
                  }}
                />
                {item.title === "Objetivos GMCP" && (
                  <img
                    src={iconoob}
                    className="absolute"
                    style={{
                      top: "-3.5rem",
                      left: "97%",
                      serSelect: 'none',
                      pointerEvents: 'none',
                      transform: "translateX(-50%)",
                      maxWidth: "100%",
                    }}
                  />
                )}
              </motion.div>
            ))}
          </div>
</div>


<div className="min-h-screen bg-[#001d4a] m-0 p-0">
  <div className="flex justify-start items-center p-4 ml-[-2rem] relative">

    {/* Versión para dispositivos móviles */}
    <img
      src={ellipse5}
      style={{ userSelect: 'none', pointerEvents: 'none' }}
      alt="Ellipse 5"
      className="block md:hidden w-30 h-50"
    />



 <img
  src={img1}
  style={{ userSelect: 'none', pointerEvents: 'none', top: '-27px' }}
  alt="Imagen 1"
  className="hidden md:block absolute left-0 z-0 w-full h-auto object-cover max-w-full img-ff"
/>


<img
  src={img8}
  alt="Group20"
  className="block md:hidden w-full object-cover absolute top-0 left-0 z-0 image-small"
  style={{ marginLeft: '0', objectFit: 'cover', top: '5rem', serSelect: 'none', pointerEvents: 'none'  }}
/>



    {/* Versión para dispositivos grandes */}
    <img
      src={ellipse5}
        style={{ userSelect: 'none', pointerEvents: 'none' }}

      alt="Ellipse 5"
      className="hidden md:block w-30 h-50"
    />

 



<div className="d-none d-md-flex absolute top-[15%] right-[10%] transform translate-x-0 text-center flex-col items-center w-full md:w-auto space-y-2 md:space-y-4 lg:space-y-6">
  <h1 className="text-white text-2xl md:text-4xl lg:text-5xl font-verlag-black leading-tight break-words">
    Consulta los cursos
  </h1>
  <h1 className="text-white text-2xl md:text-4xl lg:text-5xl font-verlag-black leading-tight break-words">
    y talleres que tenemos disponibles
  </h1>

  <p className="text-white mt-4 md:mt-6 lg:mt-8 text-sm md:text-base lg:text-lg break-words">
    Hay varias habilidades que son altamente demandadas
  </p>
  <p className="text-white mt-0 text-sm md:text-base lg:text-lg break-words">
   debido a la evolución tecnológica y las dinámicas 
    </p>
  <p className="text-white mt-0 text-sm md:text-base lg:text-lg break-words">
    de trabajo que pueden ser de mucha utilidad.
  </p>
</div>


<div
  className={`d-flex d-md-none absolute left-[53%] transform -translate-x-1/2 text-center flex-col items-center w-[90%] max-w-lg px-4 mt-96 mx-auto ${isMenuOpen || menuOpen ? 'hidden' : 'block'}`}
>
  <h1 className="text-white text-xl font-verlag-black leading-tight break-words">
    Consulta los cursos
  </h1>
  <h1 className="text-white text-xl font-verlag-black leading-tight break-words">
    y talleres que tenemos disponibles
  </h1>
  <p className="text-white mt-4 text-sm break-words">
    Hay varias habilidades que son altamente demandadas
  </p>
  <p className="text-white mt-0 text-sm break-words">
    debido a la evolución tecnológica y las dinámicas 
  </p>
  <p className="text-white mt-0 text-sm md:text-base break-words">
   de trabajo que pueden ser de mucha utilidad.
  </p>
</div>




</div>



  

<div className="relative">
<div className="flex justify-center items-start w-full mt-0 px-0 md:flex-row-reverse">
  {/* Formulario */}
  <div className="flex flex-col bg-white p-4 rounded shadow-lg w-full max-w-[577px] md:max-w-[40%] relative -top-16 md:ml-auto md:w-1/3 sm:w-full sm:mx-0 sm:max-w-none custom-range">
    <div className="w-full">
      <h2 className="text-center font-verlag-black mb-5 text-[28px]">
        Contáctanos
      </h2>
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="mb-4 border-b border-dark">
          <input
            className="form-control-plaintext w-full py-2 px-3 text-dark font-verlag-book"
            id="name"
            required
            type="text"
            placeholder="Nombre"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4 border-b border-dark">
          <input
            className="form-control-plaintext w-full py-2 px-3 text-dark font-verlag-book"
            id="email"
            required
            type="email"
            placeholder="Correo Electrónico"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4 border-b border-dark">
          <textarea
            className="form-control-plaintext w-full py-2 px-3 text-dark font-verlag-book"
            id="message"
            rows="4"
            required
            placeholder="Mensaje"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
     <div className="mb-5 mt-lg-5 flex justify-center font-verlag-book">
  <button
    className="btn btn-success btn-lg px-5 custom-button w-100 d-block d-md-inline"
    type="submit"
    disabled={loading}
    style={{ backgroundColor: '#1F7B00' }} 
  >
    {loading ? 'Enviando...' : 'Enviar'}
  </button>
</div>

      </form>
    </div>
  </div>

  {/* Imagen visible solo en pantallas grandes */}
  <div className="hidden md:flex justify-center md:w-2/3" style={{ marginTop: '-90px' }}>
    <img
      src={imgcontacto}
        style={{ userSelect: 'none', pointerEvents: 'none' }}

      alt="Imagen Contacto"
      className="w-full h-auto rounded-lg img-contact1"
    />
  </div>
</div>




  {/* Imagen visible para pantallas medianas y moviles */}
<div className="w-full flex justify-center md:hidden" style={{ marginTop: '-66px' }}>
  <img
    src={imgcontacto}
      style={{ userSelect: 'none', pointerEvents: 'none' }}

    alt="Imagen Contacto"
    className="w-full h-auto rounded-lg img-contact1"
  />
       </div>




       </div>
     </div>
  </div>
</div>
  );
};

export default Index;
