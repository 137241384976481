import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import navbarImage from '../images/navbar_image.jpeg';
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import diseno from '../images/diseno.png';

const Navbar = ({ setIsMenuOpen }) => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname.substring(1));
  const [menuOpen, setMenuOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleClick = (link) => {
    setActive(link);
    setMenuOpen(false);
    setIsMenuOpen(false);  // Close the menu and update the state in Index
  };

  const toggleMenu = () => {
    const newMenuState = !menuOpen;
    setMenuOpen(newMenuState);
    setIsMenuOpen(newMenuState);  // Update the state in Index when menu opens/closes
  };

  // Detecta la inactividad del usuario
  const resetTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId); // Reinicia el temporizador si hay actividad
    }
    setIsNavbarVisible(true); // Muestra el navbar si el usuario interactúa
    const newTimeoutId = setTimeout(() => {
      setIsNavbarVisible(false); // Oculta el navbar tras 5 segundos de inactividad
    }, 5000); // Ajusta el tiempo de inactividad según tus necesidades
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    // Reiniciar el temporizador de inactividad con cualquier interacción
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('click', resetTimeout);
    window.addEventListener('keydown', resetTimeout);
    window.addEventListener('touchstart', resetTimeout); // Para móviles

    return () => {
      // Limpia los eventos al desmontar el componente
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('click', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
      window.removeEventListener('touchstart', resetTimeout);
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setMenuOpen(false);
        setIsMenuOpen(false);   
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setIsMenuOpen]);

  const getLineStyle = () => {
    let width = '0%';
    let translateX = '-100%';

    if (active === '' || active === 'inicio') {
      width = '20.33%';
      translateX = '0%';
    } else if (location.pathname.startsWith('/vacante')) {
      width = '20.33%';
      translateX = '125.33%';
    } else if (active === 'cursos') {
      width = '16.33%';
      translateX = '320.66%';
    } else if (active === 'contacto') {
      width = '23.1%';
      translateX = '333.66%';  
    }

    return {
      width,
      transform: `translateX(${translateX})`,
      transition: 'width 0.3s ease-in-out, transform 0.3s ease-in-out',
    };
  };

  useEffect(() => {
    if (location.pathname.startsWith('/vacante')) {
      setActive('vacante');
    } else if (location.pathname.startsWith('/cursos')) {
      setActive('cursos');
    } else if (location.pathname === '/' || location.pathname === '/index') {
      setActive('inicio');  
    } else {
      setActive(location.pathname.substring(1));
    }
  }, [location.pathname]);

  return (
    <nav className={`fixed top-0 left-0 w-full z-10 bg-navy lg:backdrop-blur-lg transition-transform duration-500 ${isNavbarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="flex justify-between items-center w-full mb-2 pl-4 pr-4 bg-navy">

        <div className="flex items-center">
          <Link to="/index" className="flex items-center">
            <img src={navbarImage} alt="Navbar" className="h-10 w-auto object-contain mr-2" style={{ userSelect: 'none', pointerEvents: 'none' }} />
          </Link>
        </div>

        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-white">
            {menuOpen ? <IoCloseOutline size={24} /> : <HiOutlineMenuAlt3 size={24} />}
          </button>
        </div>

        {/* Menú de pantallas grandes */}
        <div className={`hidden lg:flex flex-col items-start space-y-2 text-verlag-light text-sm ml-3`}>
          <div className="flex items-center space-x-4">
            <Link
              to="/index"
              onClick={() => handleClick('inicio')}
              style={{ textDecoration: 'none' }}
              className={`relative px-4 py-3 font-bold ${active === 'inicio' ? 'text-yellow-400 bg-navy' : 'text-white hover:text-yellow-400'}`}
            >
              Inicio
            </Link>
            <Link
              to="/vacante"
              onClick={() => handleClick('vacante')}
              style={{ textDecoration: 'none' }}
              className={`relative px-4 py-3 font-bold ${active === 'vacante' ? 'text-yellow-400 bg-navy' : 'text-white hover:text-yellow-400 no-underline'}`}
            >
              Vacantes
            </Link>
            <Link
              to="/cursos"
              onClick={() => handleClick('cursos')}
              style={{ textDecoration: 'none' }}
              className={`relative px-4 py-3 font-bold ${active === 'cursos' ? 'text-yellow-400 bg-navy' : 'text-white hover:text-yellow-400 no-underline'}`}
            >
              Cursos
            </Link>
            <Link
              to="/contacto"
              onClick={() => handleClick('contacto')}
              style={{ textDecoration: 'none' }}
              className={`relative px-4 py-3 font-bold ${active === 'contacto' ? 'text-yellow-400 bg-navy' : 'text-white hover:text-yellow-400 no-underline'}`}
            >
              Contacto
            </Link>
          </div>
          <div className="w-full h-0.5 bg-white mt-2 relative">
            <div
              className="h-full bg-yellow-400"
              style={{ ...getLineStyle(), height: '3px', position: 'absolute', bottom: 0 }}
            />
          </div>
        </div>
      </div>

      {/* Menú movil */}
<div className={`fixed top-0 right-0 w-64 bg-[#001d4a]/80 backdrop-blur-lg z-50 flex flex-col p-4 transform transition-transform duration-300 ease-in-out ${menuOpen ? 'translate-x-0' : 'translate-x-full'} lg:hidden`}>
        <button onClick={toggleMenu} className="absolute top-4 right-4 text-white">
          <IoCloseOutline size={24} />
        </button>
        <div className="mt-12 space-y-4 w-full text-left">
          <div className="relative">
            <Link
              to="/index"
              onClick={() => handleClick('inicio')}
              className={`relative px-4 py-3 font-bold block w-full ${active === 'inicio' ? 'text-yellow-400' : 'text-white hover:text-yellow-400 no-underline'}`}
            >
              Inicio
            </Link>
            <div className={`absolute bottom-0 left-0 w-full h-0.5 ${active === 'inicio' ? 'bg-yellow-400' : 'bg-transparent'}`} />
          </div>
          <div className="relative">
            <Link
              to="/vacante"
              onClick={() => handleClick('vacante')}
              className={`relative px-4 py-3 font-bold block w-full ${active === 'vacante' ? 'text-yellow-400' : 'text-white hover:text-yellow-400 no-underline'}`}
            >
              Vacantes
            </Link>
            <div className={`absolute bottom-0 left-0 w-full h-0.5 ${active === 'vacante' ? 'bg-yellow-400' : 'bg-transparent'}`} />
          </div>
          <div className="relative">
            <Link
              to="/cursos"
              onClick={() => handleClick('cursos')}
              className={`relative px-4 py-3 font-bold block w-full ${active === 'cursos' ? 'text-yellow-400' : 'text-white hover:text-yellow-400 no-underline'}`}
            >
              Cursos
            </Link>
            <div className={`absolute bottom-0 left-0 w-full h-0.5 ${active === 'cursos' ? 'bg-yellow-400' : 'bg-transparent'}`} />
          </div>
          <div className="relative">
            <Link
              to="/contacto"
              onClick={() => handleClick('contacto')}
              className={`relative px-4 py-3 font-bold block w-full ${active === 'contacto' ? 'text-yellow-400' : 'text-white hover:text-yellow-400 no-underline'}`}
            >
              Contacto
            </Link>
            <div className={`absolute bottom-0 left-0 w-full h-0.5 ${active === 'contacto' ? 'bg-yellow-400' : 'bg-transparent'}`} />
          </div>
          <div className="absolute bottom-4 right-4 flex flex-col items-end mt-0">
            <img src={diseno} alt="Diseño" className="h-32 w-32 object-contain mb-0" style={{ serSelect: 'none', pointerEvents: 'none'  }} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
