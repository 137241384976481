import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion'; 
import Navbar from '../components/navbar';
import imgvacante from '../images/imagenvacantes.svg';
import logovacante from '../images/logovacante.svg';
import '../fonts.css';
import { Helmet } from 'react-helmet'; 
import { Link } from 'react-router-dom';
import '../vacantes.css';
import { CiSearch } from "react-icons/ci";

const Cursos = () => {
  const carouselRef = useRef(null);
  const [cursos, setCursos] = useState([]); 
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 useEffect(() => {
  const fetchCursos = async () => {
    try {
      const response = await fetch('http://101.44.24.18:8484/api/create/classes');
      const data = await response.json();
      
      
      if (Array.isArray(data)) {
        setCursos(data);
      } else {
        console.error("La respuesta de la API no es un array:", data);
        setCursos([]);  
      }
    } catch (error) {
      console.error('Error al obtener los cursos:', error);
      setCursos([]);  
    }
  };

  fetchCursos();
}, []);


  const scrollCarousel = (direction, isVertical = false) => {
    if (carouselRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      if (isVertical) {
        carouselRef.current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
      } else {
        carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCursos = cursos.filter(curso =>
    curso.nombre_curso.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
  const date = new Date(dateString);  
  const day = String(date.getDate()).padStart(2, '0');  
  const month = String(date.getMonth() + 1).padStart(2, '0');  
  const year = date.getFullYear();  

  return `${day}/${month}/${year}`;  
};


  return (
    <motion.div 
      className="bg-custom" 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      transition={{ duration: 0.5 }}
    >
      <Navbar setIsMenuOpen={setIsMenuOpen} />
      
      <Helmet>
        <title>Cursos</title>
        <meta name="description" content="Encuentra los mejores cursos en GMCP Recursos Humanos, donde conectamos talento con oportunidades educativas." />
        <meta name="keywords" content="cursos, educación, oportunidades educativas, GMCP, desarrollo profesional" />
      </Helmet>

      {/* Imagen superior */}
      <div className="position-relative">
        <img src={imgvacante} alt="Cursos" className="position-relative top-20 custom-img" style={{ width: '100%', userSelect: 'none', pointerEvents: 'none' }}  />
        <img 
          src={logovacante} 
          alt="Logo Cursos" 
          className="position-absolute start-50 translate-middle img-pantallagrande d-none d-md-block d-xl-block"
          style={{ width: '150px', marginTop: '-150px', userSelect: 'none', pointerEvents: 'none'}} 
        />
        <div className="position-absolute start-50 translate-middle text-center" style={{ marginTop: '-15px' }}>
          <p className="text-white font-verlag-condensed-book d-none d-md-block" style={{ fontSize: '35px', letterSpacing: '0.5px', whiteSpace: 'nowrap' }}>
            Educate con nosotros.
          </p>
          <p className="text-white font-verlag-condensed-book d-block d-md-none" style={{ fontSize: '17px', letterSpacing: '0.5px', whiteSpace: 'nowrap', marginTop: '9rem' }}>
            Educate con nosotros.
          </p>
        </div>
        <img 
          src={logovacante} 
          alt="Logo Cursos" 
          className="position-absolute start-50 translate-middle d-block d-md-none img-fluid"
          style={{ width: '60px', marginTop: '-5px', pointerEvents: 'none', userSelect: 'none', }}
        />
      </div>

      {/* Sección de cursos */}
    <div className="min-h-screen bg-[#001d4a] cursos-div">
        <p
          className="position-absolute d-none d-md-block font-verlag-black vacantes-text-p"
          style={{
            marginTop: '-5.5rem',
            left: '10%',
            transform: 'translateX(11%)',
            width: '100%',
            fontSize: '30px',
            color: '#E3DA00',
            maxWidth: '300px',
          }}
        >
          Cursos disponibles
        </p>

        {/* Buscar cursos en versión de pantallas pequeñas */}
        <div style={{ position: 'relative' }}>
          <p
            className="font-verlag-black vacantes-text-movile d-block d-md-none"
            style={{
              position: 'absolute', 
              top: '-2rem', 
              left: '1rem',
              right: '1rem',
              fontSize: '20px',
              color: '#E3DA00',
            }}
          >
            Cursos disponibles
          </p>
        </div>

        {/* Buscar cursos en versión pantallas grandes y medianas */}
        <div
          className="position-absolute d-none d-md-block"
          style={{
            marginTop: '-5.5rem',
            left: '50%',
            transform: 'translateX(11%)',
            width: '100%',
            maxWidth: '300px',
          }}
        >
          <input
            type="text"
            className="vacantes-text"
            style={{
              fontSize: '20px',
              border: 'none',
              borderBottom: '2px solid #fff',
              outline: 'none',
              backgroundColor: 'transparent',
              width: '100%',
              paddingRight: '2.5rem', 
              color: '#fff',
            }}
            placeholder="Buscar cursos..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <CiSearch
            style={{
              position: 'absolute',
              top: '50%',
              right: '0.5rem', 
              transform: 'translateY(-50%)',
              color: 'yellow', 
            }}
            size={24} 
          />
        </div>

        {/* Versión para dispositivos móviles */}
        <div
          className="d-block d-md-none position-relative"
          style={{
            marginTop: '7.2rem',
            padding: '0',
            marginRight: '0.5rem',
          }}
        >
          <div
            className="position-absolute bg-light"
            style={{
              top: '0',
              marginTop: '-7.5rem',
              right: '0.5rem',
              width: '100%',
              maxWidth: '280px',
              transition: 'width 0.3s ease-in-out',
              padding: '0.5rem',
              overflow: 'hidden',
            }}
          >
            <input
              type="text"
              className="form-control input-movil"
              style={{
                fontSize: '14px',
                border: 'none',
                borderBottom: '2px solid #000',
                outline: 'none',
                width: '100%',
                color: '#000',
                padding: '0.4rem',
              }}
              placeholder="Buscar cursos..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="container" style={{ marginTop: '15rem' }}>
          <div className="row">
            <div className="col-12">
              <div className="carousel-container position-relative">
                {/* Mostrar mensaje cuando no hay resultados */}
     {filteredCursos.length === 0 ? (
  <div className="text-center">
    <p className="font-verlag-black" style={{ color: '#FF0000', padding: '1rem' }}>
      Ahora mismo no hay cursos disponibles.
    </p>
  </div>
) : (
  <>
    {/* Botón Izquierdo */}
    <button className="carousel-button left d-none d-lg-block" onClick={() => scrollCarousel('left')}>
      &lt;
    </button>

    {/* Contenedor de Carrusel */}
    <div
      className="carousel d-flex align-items-center"
      ref={carouselRef}
      style={{ overflowX: 'auto', display: 'flex', whiteSpace: 'nowrap', scrollBehavior: 'smooth' }}
    >
      {/* Mapeo de cursos filtrados */}
      {filteredCursos.map((curso) => (
        <div key={curso._id} className="vacancy-card" style={{ minWidth: '300px', margin: '0 1rem', flex: '0 0 auto' }}>
          <Link to={`/cursos/${curso._id}`} style={{ textDecoration: 'none' }}>
            <img
              src={`http://101.44.24.18:8484/api/create/classes/${curso._id}/image`} // URL de la imagen
              className="img-fluid"
              alt={curso.nombre_curso}
              style={{ width: '300px', height: '200px', marginTop: '1rem', borderRadius: '10px', objectFit: 'cover', userSelect: 'none', pointerEvents: 'none' }}
            />
            <p className="font-verlag-black" style={{ color: '#1F7B00', backgroundColor: '#fff', padding: '0.7rem', borderRadius: '8px' }}>
              <span>{curso.nombre_curso}</span><br />
              <small style={{ fontSize: '0.8rem', color: '#000' }}>
                Fecha inicio: {formatDate(curso.fecha_limite)}
              </small>
            </p>
          </Link>
        </div>
      ))}
    </div>

    {/* Botón Derecho */}
    <button className="carousel-button right d-none d-lg-block" onClick={() => scrollCarousel('right')}>
      &gt;
    </button>
  </>
)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Cursos;
