import React, { useRef, useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import Navbar from '../components/navbar'; 
import { FaFilePdf } from 'react-icons/fa';
import Swal from 'sweetalert2'; 
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from 'framer-motion';  

// Configura el worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Developer = () => {
  const fileInputRef = useRef(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [isDraggingFile, setIsDraggingFile] = useState(false); // Inicializa el estado
  const [showOverlay, setShowOverlay] = useState(false); // Inicializa el estado
  
  const [fullName, setFullName] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Dummy data for demonstration
  const vacante = { nombre_vacante: 'Developer Jr' };
  const habilidades_tecnicas = [
    { _id: '1', habilidad: 'Lenguajes de Programación Básicos: Conocimiento en lenguajes como JavaScript, Python, o Java.' },
    { _id: '2', habilidad: 'Fundamentos de Bases de Datos: Habilidad para trabajar con bases de datos relacionales (MySQL, PostgreSQL) y básicas en bases de datos no relacionales (MongoDB).' },
    { _id: '3', habilidad: 'HTML y CSS: Conocimientos en HTML5 y CSS3, incluyendo diseño responsivo y uso de frameworks como Bootstrap o TailwindCSS.' },
    { _id: '4', habilidad: 'Control de Versiones: Familiaridad con Git y GitHub para el control de versiones y trabajo colaborativo.' },
    { _id: '5', habilidad: 'Conceptos de Programación Orientada a Objetos (POO): Entender los principios de POO y aplicarlos en proyectos básicos.' },

  ];
  const habilidades_blandas = [
    { _id: '1', habilidad: 'Orientado a solución de problemas.' },
    { _id: '2', habilidad: 'Buena comunicación.' },
    { _id: '3', habilidad: 'Trabajo en equipo.' },
    { _id: '4', habilidad: 'Proactiva.' },
    { _id: '5', habilidad: 'Honesta.' }
  ];
  const experiencia = 'No requiere experiencia';

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Archivo no válido',
        text: 'Por favor, selecciona solo archivos PDF.',
        confirmButtonText: 'Entendido',
      });
      setPdfFile(null);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingFile(true);
    const file = e.dataTransfer.items[0];
    if (file && file.kind === 'file' && file.type === 'application/pdf') {
      setShowOverlay(true);
    }
  };

  const handleDragLeave = () => {
    setIsDraggingFile(false);
    setShowOverlay(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default to allow drop
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
      setShowOverlay(false);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Archivo no válido',
        text: 'Por favor, arrastra solo archivos PDF.',
        confirmButtonText: 'Entendido',
      });
    }
    setIsDraggingFile(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    // Validar que haya un archivo PDF seleccionado
    if (!pdfFile) {
      Swal.fire('Error', 'Por favor, adjunta tu CV en formato PDF.', 'error');
      setSubmitting(false);
      return;
    }

    // Validar que los campos de nombre y email estén llenos
    if (!fullName || !email) {
      Swal.fire('Error', 'Por favor, completa todos los campos.', 'error');
      setSubmitting(false);
      return;
    }

    // Crear un objeto FormData
    const formData = new FormData();
    formData.append('fullname', fullName);
    formData.append('email', email);
    formData.append('cv', pdfFile);

    try {
      const response = await fetch('http://101.44.24.18:8484/api/developer/vacancies', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        Swal.fire('Éxito', 'Solicitud enviada con éxito.', 'success');
        
        // Restablecer los campos después de enviar
        setFullName('');
        setEmail('');
        setPdfFile(null);
      } else {
        const errorText = await response.text();
        Swal.fire('Error', `Error al enviar la solicitud o este correo electrónico ya fue registrado.`, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al enviar la solicitud. Por favor, intenta nuevamente.', 'error');
    } finally {
      setSubmitting(false);
      window.scrollTo(0, 0);
    }
  };

  const dragOverStyle = {
    backgroundColor: '#0a3b5b',
    border: '2px dashed #e3da00'
  };

  return (
    <motion.div 
      className="min-h-screen bg-[#012b45]"
      initial={{ opacity: 0 }} // Comienza invisible
      animate={{ opacity: 1 }} // Se vuelve visible al montarse
      exit={{ opacity: 0 }} // Se desvanece al desmontarse
      transition={{ duration: 0.5 }} // Duración de la transición
    >
      <Navbar setIsMenuOpen={setIsMenuOpen} />

      {/* Contenedor principal */}
      <div className="container mx-auto px-4 py-8">

        {/* Título para desktop y mobile */}
        <p 
          className="font-verlag-book d-none d-md-block" 
          style={{ fontSize: '35px', color: '#E3DA00', marginTop: '2rem' }}
        >
          {vacante?.nombre_vacante || 'Nombre Vacante'}
        </p>
        <p 
          className="font-verlag-book d-block d-md-none" 
          style={{ fontSize: '25px', color: '#E3DA00' }}
        >
          {vacante?.nombre_vacante || 'Nombre Vacante'}
        </p>

        {/* Descripción de perfil para desktop y mobile */}
        <p className="text-white font-verlag-book d-none d-md-block" style={{ fontSize: '25px' }}>
          Perfil de la posición
        </p>
        <p className="text-white font-verlag-book d-block d-md-none" style={{ fontSize: '18px' }}>
          Perfil de la posición
        </p>

        {/* Descripción del perfil */}
        <p className="text-white font-verlag-condensed-light d-none d-md-block"  style={{ fontSize: '25px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {'Para estudiantes de término en desarrollo de software que deseen hacer su pasantía con nosotros.'}
        </p>
        <p className="text-white font-verlag-condensed-light d-block d-md-none"  style={{ fontSize: '25px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {'Para estudiantes de término en desarrollo de software que deseen hacer su pasantía con nosotros.'}
        </p>

        {/* Habilidades técnicas y blandas */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mt-8">
          
          {/* Habilidades Técnicas */}
          <div className="flex-1">
            <p className="text-white font-verlag-black text-lg">Habilidades técnicas de la persona:</p>
            {habilidades_tecnicas?.map((item) => (
              <p key={item._id} className="text-white text-sm relative pl-6">
                <span className="absolute top-1 left-0 w-2 h-2 bg-white rounded-full"></span>
                {item.habilidad || 'Habilidad técnica'}
              </p>
            ))}
          </div>

          {/* Habilidades Blandas */}
          <div className="flex-1">
            <p className="text-white font-verlag-black text-lg">Habilidades blandas:</p>
            {habilidades_blandas?.map((item) => (
              <p key={item._id} className="text-white text-sm relative pl-6">
                <span className="absolute top-1 left-0 w-2 h-2 bg-white rounded-full"></span>
                {item.habilidad || 'Habilidad blanda'}
              </p>
            ))}
          </div>
        </div>

        {/* Experiencia y Únete a nuestro equipo */}
        <div className="mt-8">
          <p className="text-white font-helvetica-light uppercase text-lg">
            {experiencia || 'EXPERIENCIA'}
          </p>
          <p className="text-center text-white font-verlag-condensed-light text-2xl mt-6">
            Únete a nuestro equipo
          </p>
        </div>

        {/* Formulario */}
      <div className="mt-12">
  <form onSubmit={handleSubmit} className="bg-[#012b45] p-8 rounded-lg max-w-2xl mx-auto">
    
    {/* Campos de nombre y email */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <input 
        type="text" 
        id="fullName" 
        placeholder="Nombre Completo"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        required
        className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
      />
      <input 
        type="email" 
        id="email" 
        placeholder="Correo Electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
      />
    </div>

    {/* Cargar archivo */}
   <div 
  className="border-2 border-dashed border-white py-8 mt-6 flex justify-center items-center cursor-pointer"
  onDragOver={handleDragOver}
  onDragEnter={handleDragEnter}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
  onClick={handleClick}
  style={isDraggingFile ? dragOverStyle : {}}
>
  {!pdfFile ? (
    isDraggingFile ? (
      <p className="text-white font-verlag-book">Suelte aquí</p>
    ) : (
      <p className="text-white font-verlag-book">Adjuntar CV</p>
    )
  ) : (
    <div className="text-center" style={{ position: 'relative', left: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <FaFilePdf size={50} color="#fff" />
      <p className="text-white font-verlag-book">{pdfFile.name}</p>
    </div>
  )}
  <input 
    type="file" 
    ref={fileInputRef} 
    style={{ display: 'none' }} 
    onChange={handleFileChange}
    accept="application/pdf"
  />
</div>

    {/* Botón de envío */}
    <div className="mt-6 text-center">
      <button 
        type="submit"
        style={{ backgroundColor: '#1f7b00' }} 
        className="text-white py-3 mt-6 w-full rounded-lg"
        disabled={submitting}
      >
        {submitting ? 'Enviando...' : 'Aplicar'}
      </button>
    </div>

  </form>
</div>
</div>

    </motion.div>
  );
};

export default Developer;
