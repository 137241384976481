import React, { useState, useEffect } from 'react';
import footerimg from '../images/imagen_footer.svg';
import telephone from '../images/iconotelefono.svg';
import correoelect from '../images/iconocorreo.svg';
import ubicacionicon from '../images/iconoubicacion.svg';
import iconows from '../images/iconows.svg';
import iconoinstragram from '../images/Iconoinstagram.svg';
import iconofb from '../images/iconofb.svg';

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 50) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className={`bg-white text-center py-3 mt-auto w-100 ${isVisible ? 'd-block' : 'd-none'}`}>
    <img
  src={footerimg}
  alt="Footer Image"
  className="mx-auto w-18 h-auto"
  style={{ userSelect: 'none', pointerEvents: 'none' }}
  draggable="false"
/>

      {/* Desktop and Tablet Layout */}
      <div className="d-none d-md-block position-relative">
        <div className="container">
          <div className="row">
           <div className="col-md-4 col-lg-2 d-flex align-items-center">
  <a 
    href="https://wa.me/18298787214" 
    className="text-success font-weight-light ml-1 font-verlag-book d-flex align-items-center" 
    target="_blank" 
    rel="noopener noreferrer"
    style={{ textDecoration: 'none' }}
  >
    <img 
      src={iconows}  
      style={{ userSelect: 'none', pointerEvents: 'none' }} 
      alt="Telephone Icon" 
      className="w-9 h-auto ml-0 float-left" 
    />
    <span className="ml-1">+1 (829) 878-7214</span>
  </a>
</div>


            <div className="col-md-4 col-lg-3 d-flex align-items-center">
              <img src={correoelect}  style={{ userSelect: 'none', pointerEvents: 'none' }}
   alt="Correo Icon" className="w-9 h-auto ml-0 float-left" />
 <a className="text-success font-weight-light ml-2 font-verlag-book text-decoration-none">
  evaluacion@gmcpeople.com
</a>


            </div>
            <div className="col-md-4 col-lg-4 d-flex align-items-center mt-2">
  <img src={ubicacionicon}  alt="Ubicacion Icon" className="w-8 h-auto ml-0 float-left" style={{ marginTop: '-0.5rem',  userSelect: 'none', pointerEvents: 'none'  }} />
<span className="text-success font-weight-light ml-2 font-verlag-book small" style={{ paddingTop: '0px' }}>
  Calle Eugenio Deschamps #6, La Castellana, DN, 10133
</span>

            </div>
      {/* Redes sociales comentadas (version pantallas grandes) */}

             {/* 
 <div className="col-4 col-lg-1 d-flex align-items-center justify-content-center">
  <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
    <img src={iconoinstragram} alt="Icono Instagram" style={{ width: '25px', height: 'auto', userSelect: 'none', pointerEvents: 'none' }} />
  </a>
</div>

<div className="col-4 col-lg-1 d-flex align-items-center justify-content-center">
  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
    <img src={iconofb} alt="Icono Facebook" style={{ width: '25px', height: 'auto', userSelect: 'none', pointerEvents: 'none' }} />
  </a>
</div>

<div className="col-4 col-lg-1 d-flex align-items-center justify-content-center">
  <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
    <img src={iconows} alt="Icono WhatsApp" style={{ width: '25px', height: 'auto', userSelect: 'none', pointerEvents: 'none' }} />
  </a>
</div>

*/}

          </div>
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="d-block d-md-none px-4 py-2">
        <div className="d-flex flex-column align-items-start mb-4">
      <div className="d-flex align-items-center mb-2">
      <a 
    href="https://wa.me/18298787214" 
    className="text-sm text-success font-weight-light font-verlag-book d-flex align-items-center" 
    target="_blank" 
    rel="noopener noreferrer"
    style={{ textDecoration: 'none' }}
  >
    <img 
      src={iconows} 
      alt="Telephone Icon" 
      className="w-8 h-auto mr-2"  
      style={{ userSelect: 'none', pointerEvents: 'none' }} 
    />
    <span>+1 (829) 878-7214</span>
      </a>
     </div>


          <div className="d-flex align-items-center mb-2">
            <img src={correoelect} alt="Correo Icon" className="w-8 h-auto mr-2"  style={{ userSelect: 'none', pointerEvents: 'none' }} />
       <a className="text-success font-weight-light ml-1 font-verlag-book text-decoration-none">
  evaluacion@gmcpeople.com
</a>


     
        </div>
          <div className="d-flex align-items-center mb-2">
            <img src={ubicacionicon} alt="Ubicacion Icon" className="w-8 h-auto mr-2"  style={{ userSelect: 'none', pointerEvents: 'none' }}
 />
            
<span className="small-text text-success font-weight-light font-verlag-book">Calle Eugenio Deschamps #6, La Castellana, DN, 10133</span>
          </div>
        </div>


      {/* Redes sociales para version movil (solo tienes que descomentarlo) */}


 {/* 
  <div className="d-flex justify-content-center mb-4 space-x-4">
    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
      <img src={iconoinstragram} style={{ userSelect: 'none', pointerEvents: 'none' }} alt="Icono Instagram" className="w-8 h-auto" />
    </a>
    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
      <img src={iconofb} style={{ userSelect: 'none', pointerEvents: 'none' }} alt="Facebook Icon" className="w-8 h-auto" />
    </a>
    <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
      <img src={iconows} style={{ userSelect: 'none', pointerEvents: 'none' }} alt="WS Icon" className="w-8 h-auto" />
    </a>
  </div>
*/}


      </div>

      {/* Horizontal Line */}
      <div className="position-relative">
        <hr className="w-100 border-top-2 border-secondary" />
      </div>

      {/* Footer Text */}
      <div className="position-relative">
        <span className="d-block text-sm text-secondary font-weight-light text-center w-100 px-4 py-2 font-verlag-condensed-xlight">
          © 2024 Corporación GMCP. Todos los derechos reservados.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
