import React from 'react';

const NotFound = () => {
  return (

    <div className="flex items-center justify-center h-screen bg-gray-100">

      <div className="text-center">
        <h1 className="text-9xl font-bold text-blue-500">404</h1>
        <p className="text-2xl font-semibold mt-4">Oops! Página no encontrada</p>
        <p className="mt-2 text-gray-500">Parece que la página que estás buscando no existe.</p>
     
      </div>
    </div>
  );
}

export default NotFound;
