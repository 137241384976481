import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion'; // Import motion
import Navbar from '../components/navbar';
import imgvacante from '../images/imagenvacantes.svg';
import logovacante from '../images/logovacante.svg';
import '../fonts.css';
import { Helmet } from 'react-helmet'; 
import { Link } from 'react-router-dom';
import developerjr from '../images/developerjr.svg';
import developerj2 from '../images/desarrolladorjr2.svg';
import desarrolladorux from '../images/desarrolladorux.svg';
import '../vacantes.css';
import { CiSearch } from "react-icons/ci";

const Vacante = () => {
 const carouselRef = useRef(null);
  const [vacantes, setVacantes] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); 

   useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const manualVacantes = [
    {
      id: 1,
      nombre_vacante: 'Developer Jr',
      experiencia: 'Experiencia no necesaria',
      image: developerjr, 
      link: '/vacante/desarrollador'
    },
    {
      id: 2,
      nombre_vacante: 'Diseñador UI-UX',
      experiencia: '1 Año de experiencia',
      image: desarrolladorux, 
      link: "/vacante/designer"
    },
    {
      id: 3,
      nombre_vacante: 'Software Management',
      experiencia: 'Experiencia no necesaria',
      image: developerj2, 
      link: '/vacante/management'
    }
  ];

useEffect(() => {
  const fetchVacantes = async () => {
    try {
      const response = await fetch('http://101.44.24.18:8484/api/create/vacancies');
      const data = await response.json();
      setVacantes(Array.isArray(data) ? data : []);  
    } catch (error) {
      console.error('Error al obtener las vacantes:', error);
      setVacantes([]);  
    }
  };

  fetchVacantes();
}, []);


  const scrollCarousel = (direction, isVertical = false) => {
    if (carouselRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      if (isVertical) {
        carouselRef.current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
      } else {
        carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Combina las vacantes manuales con las de la API
  const allVacantes = [...manualVacantes, ...vacantes];

  // Filtra todas las vacantes (manuales y de la API) según el término de búsqueda
  const filteredVacantes = allVacantes.filter(vacante =>
    vacante.nombre_vacante.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vacante.experiencia.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <motion.div 
      className="bg-custom" 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      transition={{ duration: 0.5 }}
    >
      <Navbar setIsMenuOpen={setIsMenuOpen} />
      
      <Helmet>
        <title>Vacantes</title>
        <meta name="description" content="Encuentra las mejores oportunidades laborales en GMCP Recursos Humanos, donde conectamos talento con empresas líderes." />
        <meta name="keywords" content="vacantes, empleo, oportunidades laborales, reclutamiento, selección de personal, GMCP, desarrollo profesional" />
      </Helmet>

      {/* Imagen superior */}
      <div className="position-relative">
        <img src={imgvacante} alt="Vacantes" className="position-relative top-20 custom-img" style={{ width: '100%', userSelect: 'none', pointerEvents: 'none'}} />
        <img 
          src={logovacante} 
          alt="Logo Vacante" 
          className="position-absolute start-50 translate-middle img-pantallagrande d-none d-md-block d-xl-block"
          style={{ width: '150px', marginTop: '-150px', userSelect: 'none', pointerEvents: 'none' }} 
        />
        <div className="position-absolute start-50 translate-middle text-center" style={{ marginTop: '-15px' }}>
          <p className="text-white font-verlag-condensed-book d-none d-md-block" style={{ fontSize: '35px', letterSpacing: '0.5px', whiteSpace: 'nowrap' }}>
            Forma parte de nuestro equipo.
          </p>
          <p className="text-white font-verlag-condensed-book d-block d-md-none" style={{ fontSize: '17px', letterSpacing: '0.5px', whiteSpace: 'nowrap', marginTop: '9rem' }}>
            Forma parte de nuestro equipo.
          </p>
        </div>
        <img 
          src={logovacante} 
          alt="Logo Vacante" 
          className="position-absolute start-50 translate-middle d-block d-md-none img-fluid"
          style={{ width: '60px', marginTop: '-5px', userSelect: 'none', pointerEvents: 'none' }}
        />
      </div>

      {/* Sección de vacantes */}
    <div className="min-h-screen bg-[#001d4a] vacantes-div">
        <p
          className="position-absolute d-none d-md-block font-verlag-black vacantes-text-p"
          style={{
            marginTop: '-5.5rem',
            left: '10%',
            transform: 'translateX(11%)',
            width: '100%',
            fontSize: '30px',
            color: '#E3DA00',
            maxWidth: '300px',
          }}
        >
          Vacantes disponibles
        </p>

        {/* Buscar vacantes version pantallas pequeñas */}
        <div style={{ position: 'relative' }}>
          <p
            className="font-verlag-black vacantes-text-movile d-block d-md-none"
            style={{
              position: 'absolute', 
              top: '-2rem', 
              left: '1rem',
              right: '1rem',
              fontSize: '20px',
              color: '#E3DA00',
            }}
          >
            Vacantes disponibles
          </p>
        </div>

        {/* Buscar vacantes version pantallas grandes y medianas */}
        <div
          className="position-absolute d-none d-md-block"
          style={{
            marginTop: '-5.5rem',
            left: '50%',
            transform: 'translateX(11%)',
            width: '100%',
            maxWidth: '300px',
          }}
        >
          <input
            type="text"
            className="vacantes-text"
            style={{
              fontSize: '20px',
              border: 'none',
              borderBottom: '2px solid #fff',
              outline: 'none',
              backgroundColor: 'transparent',
              width: '100%',
              paddingRight: '2.5rem', 
              color: '#fff',
            }}
            placeholder="Buscar vacantes..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <CiSearch
            style={{
              position: 'absolute',
              top: '50%',
              right: '0.5rem', 
              transform: 'translateY(-50%)',
              color: 'yellow', 
            }}
            size={24} 
          />
        </div>

        {/* Versión para dispositivos móviles (incluye pantallas de 320px) */}
        <div
          className="d-block d-md-none position-relative"
          style={{
            marginTop: '7.2rem',
            padding: '0',
            marginRight: '0.5rem', // Ajustar margen derecho
          }}
        >
          <div
            className="position-absolute bg-light"
            style={{
              top: '0',
              marginTop: '-7.5rem',
              right: '0.5rem', // Ajustar borde derecho para pantallas pequeñas
              width: '100%',
              maxWidth: '280px', // Reducir maxWidth para pantallas de 320px
              transition: 'width 0.3s ease-in-out',
              padding: '0.5rem',
              overflow: 'hidden',
            }}
          >
            <input
              type="text"
              className="form-control input-movil"
              style={{
                fontSize: '14px', // Reducir fontSize para pantallas pequeñas
                border: 'none',
                borderBottom: '2px solid #000',
                outline: 'none',
                width: '100%',
                color: '#000',
                padding: '0.4rem', // Reducir padding para mayor ajuste
              }}
              placeholder="Buscar vacantes..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="container" style={{ marginTop: '15rem' }}>
          <div className="row">
            <div className="col-12">
              <div className="carousel-container position-relative">
                {/* Mostrar mensaje cuando no hay resultados */}
                {filteredVacantes.length === 0 ? (
                  <div className="text-center">
                    <p className="font-verlag-black" style={{ color: '#FF0000', padding: '1rem' }}>
                      La vacante que buscas no existe
                    </p>
                  </div>
                ) : (
                  <>
                    {/* Botón Izquierdo */}
                    <button className="carousel-button left d-none d-lg-block" onClick={() => scrollCarousel('left')}>
                      &lt;
                    </button>

                    {/* Contenedor de Carrusel */}
                    <div
                      className="carousel d-flex align-items-center"
                      ref={carouselRef}
                      style={{ overflowX: 'auto', display: 'flex', whiteSpace: 'nowrap', scrollBehavior: 'smooth' }}
                    >
                      {/* Mapeo de vacantes filtradas */}
                      {filteredVacantes.map((vacante) => (
                        <div key={vacante.id || vacante._id} className="vacancy-card" style={{ minWidth: '300px', margin: '0 1rem', flex: '0 0 auto' }}>
                          <Link to={vacante.link || `/vacante/${vacante._id}`} style={{ textDecoration: 'none' }}>
                            <img
                              src={vacante.image || `http://101.44.24.18:8484/api/create/vacancies/${vacante._id}/image`}
                              className="img-fluid"
                              alt={vacante.nombre_vacante}
                              style={{ width: '300px', height: '200px', marginTop: '1rem', borderRadius: '10px', objectFit: 'cover', userSelect: 'none', pointerEvents: 'none' }}
                            />
                            <p className="font-verlag-black" style={{ color: '#1F7B00', backgroundColor: '#fff', padding: '0.7rem', borderRadius: '8px' }}>
                              <span>{vacante.nombre_vacante}</span><br />
                              <small style={{ fontSize: '0.8rem', color: '#000' }}>{vacante.experiencia}</small>
                            </p>
                          </Link>
                        </div>
                      ))}
                    </div>

                  


                    {/* Botón Derecho */}
                    <button className="carousel-button right d-none d-lg-block" onClick={() => scrollCarousel('right')}>
                      &gt;
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Vacante;
