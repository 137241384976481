import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Index from './pages/';
import Contacto from './pages/contacto';
import Vacante from './pages/vacante';
import Developer from './pages/developer';
import './fonts.css';
import Footer from './components/footer';
import './deleloper.css';
import './vacantespage.css';
import Vacancies from './pages/vacancies';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NotFound from './pages/notfound';
import SoftwareManagement from './pages/softwaremanage';
import Designer from './pages/designer';
import './indexx.css';
import Cursos from './pages/cursos';
import Curso from './pages/curso';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <main className="main-content flex-grow">
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/index" element={<Index />} />
            <Route path="/404" element={<NotFound />} />

            <Route path="/cursos" element={<Cursos />} />
            <Route path="/cursos/:id" element={<Curso />} />

            <Route path="/vacante" element={<Vacante />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/vacante/:id" element={<Vacancies />} />
            <Route path="*" element={<NotFound />} />

            <Route path="/vacante/desarrollador" element={<Developer />} />
            <Route path="/vacante/designer" element={<Designer />} />
            <Route path="/vacante/management" element={<SoftwareManagement />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
