import React, { useRef, useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import Navbar from '../components/navbar';
import { FaFilePdf } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaDownload } from 'react-icons/fa'; 

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Classes = () => {
  const fileInputRef = useRef(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [isDraggingFile, setIsDraggingFile] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [course, setCourse] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Archivo no válido',
        text: 'Por favor, selecciona solo archivos PDF.',
        confirmButtonText: 'Entendido',
      });
      setPdfFile(null);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingFile(true);
    const file = e.dataTransfer.items[0];
    if (file && file.kind === 'file' && file.type === 'application/pdf') {
      setShowOverlay(true);
    }
  };

  const handleDragLeave = () => {
    setIsDraggingFile(false);
    setShowOverlay(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default to allow drop
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
      setShowOverlay(false);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Archivo no válido',
        text: 'Por favor, arrastra solo archivos PDF.',
        confirmButtonText: 'Entendido',
      });
    }
    setIsDraggingFile(false);
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await fetch(`http://101.44.24.18:8484/api/create/classes/${id}`);
        if (!response.ok) {
          if (response.status === 404) {
            navigate('/404');
            return;
          } else {
            throw new Error('Error al obtener los detalles del curso');
          }
        }

        const data = await response.json();
        setCourse(data);
      } catch (error) {
        console.error('Error al obtener los detalles del curso:', error);
        navigate('/404');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchCourse();
    }

    window.addEventListener('dragover', handleDragEnter);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragleave', handleDragLeave);

    return () => {
      window.removeEventListener('dragover', handleDragEnter);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, [id, navigate]);

const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!pdfFile) {
        Swal.fire('Error', 'Por favor adjunta un archivo PDF.', 'error');
        setSubmitting(false);
        return;
    }

    const formData = new FormData();
    formData.append('fullname', fullName);
    formData.append('email', email);
    formData.append('cv', pdfFile);

    // Agregar detalles del curso
    if (course && course.nombre_curso) {
        formData.append('clase', course.nombre_curso);
    } else {
        Swal.fire('Error', 'No se encontró la información del curso.', 'error');
        setSubmitting(false);
        return;
    }

    // Agregar el precio al formData
    if (course && course.precio_y_moneda) {
        formData.append('precio', course.precio_y_moneda);
    } else {
        Swal.fire('Error', 'No se encontró el precio del curso.', 'error');
        setSubmitting(false);
        return;
    }

    // Agregar la fecha de inicio al formData
    if (deadline) {
        formData.append('fecha_clase', deadline);
    } else {
        Swal.fire('Error', 'No se encontró la fecha de inicio del curso.', 'error');
        setSubmitting(false);
        return;
    }

    try {
        const response = await fetch('http://101.44.24.18:8484/api/create/students', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.error || 'Hubo un error al enviar los datos.';
            
            Swal.fire({
                title: 'Error',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'OK',
            });
            setSubmitting(false);
            return;
        }

        Swal.fire({
    title: '¡Te haz registrado correctamente!',
    text: 'Se te ha enviado un correo electrónico con la información correspondiente. Por favor, revisa la bandeja de spam en caso de que no lo encuentres en la bandeja de entrada.',
    icon: 'success',
    confirmButtonText: 'OK',
});


        // Limpiar los campos después de enviar
        setFullName('');
        setEmail('');
        setPdfFile(null);
    } catch (error) {
        console.error('Error al enviar los datos:', error);
        Swal.fire('Error', 'Hubo un error al enviar los datos', 'error');
    }

    setSubmitting(false);
};


  // Convertir fecha límite a formato dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Obtener la fecha límite y convertirla
  const deadline = course?.fecha_limite ? formatDate(course.fecha_limite) : '';

  if (loading) {
    return <p>Cargando curso...</p>;
  }

  return (
    <div className="min-h-screen bg-[#012b45]">
      <Navbar setIsMenuOpen={setIsMenuOpen} />

      <Helmet>
        <title>Curso</title>
        <meta name="description" content="Obtén información sobre los cursos disponibles y aplica hoy mismo." />
        <meta name="keywords" content="cursos, educación, habilidades, formación, desarrollo profesional" />
      </Helmet>

      <motion.div 
        className="container mx-auto px-4 py-8" 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        exit={{ opacity: 0 }} 
        transition={{ duration: 0.5 }}
      >
        <p className="font-verlag-book d-none d-md-block" style={{ fontSize: '35px', color: '#E3DA00', marginTop: '2rem'}}>
          {course?.nombre_curso || 'Nombre Curso'}
        </p>
        <p className="font-verlag-book d-block d-md-none" style={{ fontSize: '35px', color: '#E3DA00' }}>
          {course?.nombre_curso || 'Nombre Curso'}
        </p>

        <p className="text-white font-verlag-book d-none d-md-block" style={{ fontSize: '25px' }}>
          Perfil del curso
        </p>
        <p className="text-white font-verlag-book d-block d-md-none" style={{ fontSize: '18px' }}>
          Perfil del curso
        </p>

        <p className="text-white font-verlag-condensed-light d-none d-md-block" style={{  fontSize: '25px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {course?.perfil_position}
        </p>
        <p className="text-white font-verlag-condensed-light d-block d-md-none" style={{ fontSize: '25px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {course?.perfil_position}
        </p>

         <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mt-8">
          <div className="flex-1">
            <p className="text-white font-verlag-black text-lg">Habilidades técnicas del curso:</p>
            {course?.habilidades_tecnicas?.map((item) => (
              <p key={item._id} className="text-white text-sm relative pl-6">
                <span className="absolute top-1 left-0 w-2 h-2 bg-white rounded-full"></span>
                {item.tecnica}
              </p>
            ))}
          </div>

          <div className="flex-1">
            <p className="text-white font-verlag-black text-lg">Habilidades blandas del curso:</p>
            {course?.habilidades_blandas?.map((item) => (
              <p key={item._id} className="text-white text-sm relative pl-6">
                <span className="absolute top-1 left-0 w-2 h-2 bg-white rounded-full"></span>
                {item.habilidad}
              </p>
            ))}
          </div>
        </div>

        <div className="mt-8">
          <p className="text-white font-helvetica-light uppercase text-lg">
            {course?.precio_y_moneda || 'PRECIO'}
          </p>
        </div>

        {/* Mostrar fecha límite */}
        <div className="mt-4">
          <p className="text-white font-helvetica-light uppercase text-lg">
            Fecha de inicio: {deadline || 'Fecha no disponible'}
          </p>
        </div>


           <div className="mt-8">

          <p className="text-center text-white font-verlag-condensed-light text-2xl mt-6">
            Educate con nosotros
          </p>
        </div>

        {/* Sección de descarga del brochure */}
<div className="mt-8 flex justify-center items-center">
  <a 
    href="#"
    onClick={(e) => {
      e.preventDefault(); 
      window.open(`http://101.44.24.18:8484/api/create/classes/${id}/pdf`, '_blank');
    }} 
    className="flex items-center text-white bg-green-600 hover:bg-green-700 p-3 rounded-lg"
    style={{ textDecoration: 'none' }}
    title="Descargar folleto del curso"
  >
    <FaDownload className="mr-2" size={20} /> 
    Descargar folleto del curso o taller
  </a>
</div>



        {/* Formulario */}
        <div className="mt-12">
          <form onSubmit={handleSubmit} className="bg-[#012b45] p-8 rounded-lg max-w-2xl mx-auto">
            
            {/* Campos de nombre y email */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <input 
                type="text" 
                id="fullName" 
                placeholder="Nombre Completo"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
              />
              <input 
                type="email" 
                id="email" 
                placeholder="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
              />
            </div>

            {/* Cargar archivo */}
            {/* Área de arrastrar y soltar */}
            <div 
              className={`border-2 ${isDraggingFile ? 'border-yellow-500' : 'border-white'} border-dashed py-8 mt-6 flex justify-center items-center cursor-pointer`}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleClick}
            >
              {!pdfFile ? (
                <p className="text-white font-verlag-book">
                  {isDraggingFile ? 'Suelte aquí' : 'Adjuntar CV'}
                </p>
              ) : (
                <div className="text-center" style={{ position: 'relative', left: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FaFilePdf size={50} color="#fff" />
                  <span style={{ color: '#fff', marginTop: '8px' }}>{pdfFile.name}</span>
                </div>
              )}

              <input 
                type="file" 
                accept="application/pdf" 
                ref={fileInputRef} 
                required 
                className="hidden" 
                onChange={handleFileChange}
              />
            </div>

            {/* Botón de aplicar */}
            <button 
              type="submit"
              style={{ backgroundColor: '#1f7b00' }} 
              className="text-white py-3 mt-6 w-full rounded-lg"
              disabled={submitting}
            >
              {submitting ? 'Enviando...' : 'Aplicar'}
            </button>
          </form>
        </div>
      </motion.div> {/* Cerrar el motion.div */}
    </div>
  );
};

export default Classes;
