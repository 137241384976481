import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar';
import imgvacante from '../images/imagenvacantes.svg';
import logovacante from '../images/logovacante.svg';
import '../fonts.css';
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion'; 
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa'; 

const Contacto = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); 

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    };

    try {
      const response = await fetch('http://101.44.24.18:8484/api/contact/send-message', requestOptions);
      if (!response.ok) {
        throw new Error('Error al enviar el mensaje');
      }
      const data = await response.json();
      console.log('Mensaje enviado:', data);

      Swal.fire({
        title: '¡Éxito!',
        text: 'Tu mensaje ha sido enviado correctamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });

      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudo enviar el mensaje. Por favor, inténtalo de nuevo más tarde.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    } finally {
      setLoading(false);
    }
  };

  const animationProps = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.5 }
  };

  return (
    <div className="bg-custom">
      <Navbar setIsMenuOpen={setIsMenuOpen} />

      <Helmet>
        <title>Contacto</title>
        <meta name="description" content="Ponte en contacto con GMCP Recursos Humanos para obtener más información sobre nuestros servicios de contratación y gestión de personal." />
        <meta name="keywords" content="contacto, GMCP, recursos humanos, atención al cliente, soporte, contratación, gestión de personal, consultas" />
      </Helmet>

      <div className="position-relative">
        <img src={imgvacante} alt="Vacantes" className="position-relative top-20 custom-img" style={{ width: '100%', userSelect: 'none', pointerEvents: 'none' }} />
        <img
          src={logovacante}
          alt="Logo Vacante"
          className="position-absolute start-50 translate-middle img-pantallagrande d-none d-md-block d-xl-block"
          style={{ width: '150px', marginTop: '-150px', userSelect: 'none', pointerEvents: 'none' }}
        />
        <div className="position-absolute start-50 translate-middle text-center" style={{ marginTop: '-15px' }}>
          <p className="text-white font-verlag-condensed-book d-none d-md-block" style={{ fontSize: '35px', letterSpacing: '0.5px', whiteSpace: 'nowrap' }}>
            Ante cualquier duda, estamos a la orden.
          </p>
          <p className="text-white font-verlag-condensed-book d-block d-md-none" style={{ fontSize: '12px', letterSpacing: '0.5px', whiteSpace: 'nowrap', marginTop: '9rem' }}>
            Ante cualquier duda, estamos a la orden.
          </p>
        </div>
        <img
          src={logovacante}
          alt="Logo Vacante"
          className="position-absolute start-50 translate-middle d-block d-md-none img-fluid"
          style={{ width: '60px', marginTop: '-5px', userSelect: 'none', pointerEvents: 'none' }}
        />
      </div>

<div className="min-h-screen bg-[#001d4a] contacto-div">
  <div className="container mx-auto px-4 py-10">
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-start lg:mt-16">
    <motion.form 
      onSubmit={handleSubmit} 
      className="bg-[#001d4a] p-8 rounded-lg mt-8 lg:mt-0" 
      {...animationProps}
    >
      <h2 className="text-center font-verlag-black mb-5 text-[28px] text-white">Envianos un mensaje</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Nombre"
          value={formData.name}
          onChange={handleChange}
          required
          className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Correo Electrónico"
          value={formData.email}
          onChange={handleChange}
          required
          className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
        />
      </div>

      <div className="mt-6">
        <textarea
          id="message"
          name="message"
          placeholder="Mensaje"
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
          className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
        ></textarea>
      </div>

      <div className="mt-6 text-center">
        <button
          type="submit"
          style={{ backgroundColor: '#1f7b00' }}
          className="text-white py-3 mt-6 w-full rounded-lg"
          disabled={loading}
        >
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </div>
    </motion.form>


 <motion.div
      className="bg-[#001d4a] p-12 rounded-lg flex flex-col items-start justify-center text-white font-verlag-book lg:mt-16"
      initial={{ opacity: 0, y: 20 }}  
      animate={{ opacity: 1, y: 0 }}  
      transition={{ duration: 0.5 }}  
    >
      <h3 className="text-[28px] mb-8 text-center">Contáctanos directamente</h3>

      <div className="flex flex-col w-full space-y-6">
        <p className="flex items-center text-xl">
          <a 
            href="https://wa.me/18298787214" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center no-underline"
          >
            <FaWhatsapp className="text-green-500 mr-4 text-3xl" /> 
            <span className="text-white text-xl">+1 (829) 878-7214</span>
          </a>
        </p>
        
       <p className="flex items-center text-xl">
  <a className="flex items-center no-underline text-white">
    <FaEnvelope className="text-yellow-500 mr-4 text-3xl" /> 
    <span className="text-xl">evaluacion@gmcpeople.com</span>
  </a>
</p>

      </div>
    </motion.div>

  </div>
</div>

</div>


    </div>
  );
};

export default Contacto;
