import React, { useRef, useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import Navbar from '../components/navbar';
import { FaFilePdf } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';  // Importar motion

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Vacantes = () => {
  const fileInputRef = useRef(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingFile, setIsDraggingFile] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);  
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [vacante, setVacante] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file && file.type === 'application/pdf') {
    setPdfFile(file);
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Archivo no válido',
      text: 'Solo se permiten archivos PDF.',
      confirmButtonText: 'Entendido',
    });
    setPdfFile(null);
  }
};


  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

const handleDragEnter = (e) => {
  e.preventDefault();
  setIsDragging(true);
  const file = e.dataTransfer.items[0];
  if (file && file.kind === 'file') {
    setIsDraggingFile(true);
    setShowOverlay(true); 
  }
};

  const handleDragLeave = () => {
    setIsDragging(false);
    setIsDraggingFile(false);
    setShowOverlay(false); 
  };

const handleDrop = (e) => {
  e.preventDefault();
  const file = e.dataTransfer.files[0];
  if (file && file.type === 'application/pdf') {
    setPdfFile(file);
    setShowOverlay(false); 
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Archivo no válido',
      text: 'Solo se permiten archivos PDF.',
      confirmButtonText: 'Entendido',
    });
    setPdfFile(null);
  }
  setIsDragging(false);
  setIsDraggingFile(false);
};


  useEffect(() => {
    const fetchVacante = async () => {
      try {
        const response = await fetch(`http://101.44.24.18:8484/api/create/vacancies/${id}`);
        if (!response.ok) {
          if (response.status === 404) {
            navigate('/404');
            return;
          } else {
            throw new Error('Error al obtener los detalles de la vacante');
          }
        }

        const data = await response.json();
        setVacante(data);
      } catch (error) {
        console.error('Error al obtener los detalles de la vacante:', error);
        navigate('/404');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchVacante();
    }

    window.addEventListener('dragover', handleDragEnter);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragleave', handleDragLeave);

    return () => {
      window.removeEventListener('dragover', handleDragEnter);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, [id, navigate]);

const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    // Validar si el archivo PDF ha sido seleccionado
    if (!pdfFile) {
        Swal.fire('Error', 'Por favor adjunta un archivo PDF.', 'error');
        setSubmitting(false);
        return;
    }

    const formData = new FormData();
    formData.append('fullname', fullName);
    formData.append('email', email);
    formData.append('cv', pdfFile);

    // Verificar que vacante tenga información válida
    if (vacante && vacante.nombre_vacante) {
        formData.append('vacante', vacante.nombre_vacante);
    } else {
        Swal.fire('Error', 'No se encontró la información de la vacante.', 'error');
        setSubmitting(false);
        return;
    }

    try {
        const response = await fetch(`http://101.44.24.18:8484/api/vacancies/employees`, {
            method: 'POST',
            body: formData,
        });

        // Manejo de respuesta de error del servidor
        if (!response.ok) {
            const errorData = await response.json(); 
            const errorMessage = errorData.error || 'Hubo un error al enviar los datos.';

            // Mensaje específico si el correo ya fue registrado
            if (errorMessage.includes('Ya existe un empleado registrado con este email y vacante')) {
                Swal.fire({
                    title: 'Error',
                    text: 'Ya te registraste para esta vacante.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
            setSubmitting(false);
            return;
        }

        // Mensaje de éxito al enviar los datos correctamente
        Swal.fire({
            title: '¡Éxito!',
            text: 'Datos enviados exitosamente.',
            icon: 'success',
            confirmButtonText: 'OK',
        });

        // Limpiar los campos del formulario después del envío
        setFullName('');
        setEmail('');
        setPdfFile(null);
    } catch (error) {
        console.error('Error al enviar los datos:', error);
        Swal.fire('Error', 'Hubo un error al enviar los datos', 'error');
    }

    setSubmitting(false);
};


  if (loading) {
    return <p>Cargando vacantes...</p>;
  }

  return (
    <div className="min-h-screen bg-[#012b45]">
      <Navbar setIsMenuOpen={setIsMenuOpen} />
      
      <Helmet>
        <title>Vacantes</title>
        <meta name="description" content="Encuentra las mejores oportunidades laborales en GMCP Recursos Humanos, donde conectamos talento con empresas líderes." />
        <meta name="keywords" content="vacantes, empleo, oportunidades laborales, reclutamiento, selección de personal, GMCP, desarrollo profesional" />
      </Helmet>

      {/* Contenedor principal */}
      <motion.div 
        className="container mx-auto px-4 py-8" 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        exit={{ opacity: 0 }} 
        transition={{ duration: 0.5 }}
      >
        
        {/* Título para desktop y mobile */}
        <p 
          className="font-verlag-book d-none d-md-block" 
          style={{ fontSize: '35px', color: '#E3DA00', marginTop: '2rem'}}
        >
          {vacante?.nombre_vacante || 'Nombre Vacante'}
        </p>
        <p 
          className="font-verlag-book d-block d-md-none" 
          style={{ fontSize: '25px', color: '#E3DA00' }}
        >
          {vacante?.nombre_vacante || 'Nombre Vacante'}
        </p>

        {/* Descripción de perfil para desktop y mobile */}
        <p className="text-white font-verlag-book d-none d-md-block" style={{ fontSize: '25px' }}>
          Perfil de la posición
        </p>
        <p className="text-white font-verlag-book d-block d-md-none" style={{ fontSize: '18px' }}>
          Perfil de la posición
        </p>

        {/* Descripción del perfil */}
      <p className="text-white font-verlag-condensed-light d-none d-md-block" style={{ fontSize: '25px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
  {vacante?.perfil_position}
</p>
<p className="text-white font-verlag-condensed-light d-block d-md-none" style={{ fontSize: '25px', wordBreak: 'break-word', whiteSpace: 'normal' }}>
  {vacante?.perfil_position}
</p>

        {/* Habilidades técnicas y blandas */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mt-8">
          
          {/* Habilidades Técnicas */}
          <div className="flex-1">
            <p className="text-white font-verlag-black text-lg">Habilidades técnicas de la persona:</p>
            {vacante?.habilidades_tecnicas?.map((item) => (
              <p key={item._id} className="text-white text-sm relative pl-6">
                <span className="absolute top-1 left-0 w-2 h-2 bg-white rounded-full"></span>
                {item.tecnica}
              </p>
            ))}
          </div>

          {/* Habilidades Blandas */}
          <div className="flex-1">
            <p className="text-white font-verlag-black text-lg">Habilidades blandas:</p>
            {vacante?.habilidades_blandas?.map((item) => (
              <p key={item._id} className="text-white text-sm relative pl-6">
                <span className="absolute top-1 left-0 w-2 h-2 bg-white rounded-full"></span>
                {item.habilidad}
              </p>
            ))}
          </div>
        </div>

        {/* Experiencia y Únete a nuestro equipo */}
        <div className="mt-8">
          <p className="text-white font-helvetica-light uppercase text-lg">
            {vacante?.experiencia || 'EXPERIENCIA'}
          </p>
          <p className="text-center text-white font-verlag-condensed-light text-2xl mt-6">
            Únete a nuestro equipo
          </p>
        </div>

        {/* Formulario */}
        <div className="mt-12">
          <form onSubmit={handleSubmit} className="bg-[#012b45] p-8 rounded-lg max-w-2xl mx-auto">
            
            {/* Campos de nombre y email */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <input 
                type="text" 
                id="fullName" 
                placeholder="Nombre Completo"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
              />
              <input 
                type="email" 
                id="email" 
                placeholder="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-2 border-b-2 border-white bg-transparent text-white font-verlag-book"
              />
            </div>

            {/* Cargar archivo */}
            {/* Área de arrastrar y soltar */}
         <div 
  className={`border-2 ${isDraggingFile ? 'border-yellow-500' : 'border-white'} border-dashed py-8 mt-6 flex justify-center items-center cursor-pointer`}
  onDragOver={handleDragOver}
  onDragEnter={handleDragEnter}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
  onClick={handleClick}
>
  {!pdfFile ? (
    <p className="text-white font-verlag-book">
      {isDraggingFile ? 'Suelte aquí' : 'Adjuntar CV'}
    </p>
  ) : (
    <div className="text-center" style={{ position: 'relative', left: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <FaFilePdf size={50} color="#fff" />
      <span style={{ color: '#fff', marginTop: '8px' }}>{pdfFile.name}</span>
    </div>
  )}

  <input 
    type="file" 
    accept="application/pdf" 
    ref={fileInputRef} 
    required 
    className="hidden" 
    onChange={handleFileChange}
  />
</div>


            {/* Botón de aplicar */}
            <button 
              type="submit"
              style={{ backgroundColor: '#1f7b00' }} 
              className="text-white py-3 mt-6 w-full rounded-lg"
              disabled={submitting}
            >
              {submitting ? 'Enviando...' : 'Aplicar'}
            </button>
          </form>
        </div>
      </motion.div> {/* Cerrar el motion.div */}
    </div>
  );
}

export default Vacantes;
